import React, { useState, useEffect } from "react";
import { IoIosClose } from "react-icons/io";
import { FcSettings } from "react-icons/fc";
import { Link } from "react-router-dom";

function Notification() {
  // State to control visibility of the notification
  const [isVisible, setIsVisible] = useState(true);

  // Check local storage on component mount to set visibility
  useEffect(() => {
    const notificationClosed = localStorage.getItem("notificationClosed");
    if (notificationClosed === "true") {
      setIsVisible(false);
    }
  }, []);

  // Function to close the notification
  const closeNotification = () => {
    setIsVisible(false);
    localStorage.setItem("notificationClosed", "true"); // Persist the state in local storage
  };

  return (
    <>
      {isVisible && (
        <div className="w-full z-[100] top-0 left-0 flex justify-center items-center">
          <div className="w-full max-w-full px-4 py-2 bg-gray-200 ring-1 ring-black ring-opacity-5">
            <div className="flex items-start justify-between">
              <div className="flex-shrink-0 my-auto">
                <FcSettings size={30} />
              </div>
              <div className="ml-3 flex-1 leading-tight sm:text-[14px] text-[12px]">
                <p className="sm:text-[16px] text-[14px] font-bold">
                  New Update 2.0V
                </p>
                <p className="sm:text-[14px] text-[12px]">
                  WhatsApp URL Sharing, Menu Card Feature, Admin Access
                </p>
                <p>
                  <Link to="/updates" className="font-bold">
                    Know More
                  </Link>
                </p>
              </div>
              <div>
                <button
                  type="button"
                  onClick={closeNotification} // Close the notification when clicked
                  className="inline-flex text-black hover:text-white bg-gray-200"
                >
                  <IoIosClose size={30} />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Notification;
