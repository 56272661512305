import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoMdLogOut } from "react-icons/io";
import { Companylogo } from "./GlobalData";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userData");
    navigate("/");
  };

  // Hide Navbar on the login page
  const showNavbar = location.pathname !== "/";

  return (
    showNavbar && (
      <nav className="bg-black/80 backdrop-blur-lg z-[100] py-2 px-4 flex justify-between items-center w-full top-0 sticky shadow-lg">
        {/* Left Section: Logo */}
        <div className="flex items-center">
          <a
            href="/dashboard"
            className="flex items-center text-white text-sm sm:text-xl font-bold hover:text-gray-300 transition-all"
          >
            <img
              src={Companylogo}
              alt="Company Logo"
              className="cursor-pointer font-sans w-6 sm:w-8 h-auto mr-2"
            />
            CRM Geni
          </a>
        </div>

        {/* Right Section: Logout */}
        <div
          onClick={handleLogout}
          className="flex items-center text-white hover:text-red-500 transition-all hover:cursor-pointer"
        >
          <IoMdLogOut className="text-2xl sm:text-3xl" />
          <span className="hidden sm:inline-block ml-2 text-sm">Logout</span>
        </div>
      </nav>
    )
  );
};

export default Navbar;
