import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import { IoTrashSharp, IoCart, IoEyeOutline } from "react-icons/io5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

import {
  INVOICE_CRUD_API,
  CUSTOMERS_CRUD_API,
  PRODUCTS_CRUD_API,
  SETTINGS_CRUD_API,
} from "../../hooks/APIHooks";

const InvoicesPage = () => {
  const [invoices, setInvoices] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(today);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [phoneFilter, setPhoneFilter] = useState("");
  const [settings, setSettings] = useState({
    companylogo: "",
    companyName: "",
    companyPhone: "",
    companyPhone2: "",
    companyEmail: "",
    companyAddress: "",
    GSTnumber: "",
    Tax: "",
  });
  const [form, setForm] = useState({
    invoice_number: "",
    invoice_date: today,
    customers_name: "",
    customers_email: "",
    customers_phone: "",
    customers_address: "",
    products: [
      { name: "", quantity: "1", price: "", category: "", description: "" },
    ],
    due_date: today,
    discount: "",
    total_amount: "",
    GSTAmount: "",
    status: "Paid",
  });

  const [editId, setEditId] = useState(null);
  const [message, setMessage] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    fetchInvoices();
    fetchCustomerData();
    fetchProductData();
  }, []);

  useEffect(() => {
    // Filter invoices based on date range
    const filtered = invoices.filter((invoice) => {
      const invoiceDate = new Date(invoice.invoice_date);
      const start = startDate
        ? new Date(startDate)
        : new Date(-8640000000000000); // Min date
      const end = endDate ? new Date(endDate) : new Date(8640000000000000); // Max date
      return invoiceDate >= start && invoiceDate <= end;
    });
    setFilteredInvoices(filtered);
  }, [startDate, endDate, invoices]);

  useEffect(() => {
    console.log("Invoices data:", invoices);
    console.log("Customer data:", customerData);
    console.log("Product data:", productData);
  }, [invoices, customerData, productData]);

  useEffect(() => {
    // Calculate total amount whenever products or discount change
    calculateTotalAmount();
  }, [form.products, form.discount]);

  useEffect(() => {
    // Filter invoices based on date range
    const filtered = invoices.filter((invoice) => {
      const invoiceDate = new Date(invoice.invoice_date);
      const start = startDate
        ? new Date(startDate)
        : new Date(-8640000000000000); // Min date
      const end = endDate ? new Date(endDate) : new Date(8640000000000000); // Max date
      return invoiceDate >= start && invoiceDate <= end;
    });
    setFilteredInvoices(filtered);
  }, [startDate, endDate, invoices]);

  const fetchCustomerData = async () => {
    try {
      const response = await axios.get(CUSTOMERS_CRUD_API);
      setCustomerData(response.data);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const fetchProductData = async () => {
    try {
      const response = await axios.get(PRODUCTS_CRUD_API);
      setProductData(response.data);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [id]: value,
    }));
  };

  const handleCustomerNameChange = (e) => {
    const selectedValue = e.target.value;
    const selectedCustomer = customerData.find(
      (customer) =>
        customer.name === selectedValue || customer.phone === selectedValue
    );

    if (selectedCustomer) {
      setForm({
        ...form,
        customers_name: selectedCustomer.name,
        customers_email: selectedCustomer.email,
        customers_phone: selectedCustomer.phone,
        customers_address: selectedCustomer.address,
      });
    }
  };

  const handleProductChange = (index, e) => {
    const { name, value } = e.target;
    const updatedProducts = [...form.products];

    if (name === "name") {
      const selectedProduct = productData.find(
        (product) => product.name === value
      );
      const price = selectedProduct ? selectedProduct.price : "";
      const category = selectedProduct ? selectedProduct.category : "";
      const description = selectedProduct ? selectedProduct.description : "";

      updatedProducts[index] = {
        ...updatedProducts[index],
        name: value,
        price: price,
        category: category,
        description: description,
      };
    } else {
      updatedProducts[index] = {
        ...updatedProducts[index],
        [name]: value,
      };
    }

    setForm((prevForm) => ({
      ...prevForm,
      products: updatedProducts,
    }));

    // Recalculate total after product change
    calculateTotalAmount(updatedProducts);
  };

  const handleAddProduct = () => {
    setForm((prevForm) => ({
      ...prevForm,
      products: [...prevForm.products, { name: "", quantity: "1", price: "" }],
    }));
  };

  const calculateTotalAmount = (updatedProducts) => {
    const totalAmount = (updatedProducts || form.products).reduce(
      (total, product) => {
        const price = parseFloat(product.price) || 0;
        const quantity = parseInt(product.quantity) || 1;
        return total + price * quantity;
      },
      0
    );

    const discount = parseFloat(form.discount) || 0;
    const totalWithDiscount = totalAmount - discount;

    const gstRate = parseFloat(settings.Tax); // Assuming the Tax setting holds the Excluded GST percentage
    const gstAmount = (totalWithDiscount * gstRate) / (100 + gstRate); // Calculate GST amount from total

    setForm((prevForm) => ({
      ...prevForm,
      total_amount: totalWithDiscount.toFixed(2),
      GSTAmount: gstAmount.toFixed(2),
    }));
  };

  const handleAddOrUpdate = async (e) => {
    e.preventDefault();
    try {
      const formattedProducts = form.products.reduce((acc, product, index) => {
        acc[`Product${index + 1}`] = product.name;
        acc[`Quantity${index + 1}`] = product.quantity;
        acc[`Price${index + 1}`] = product.price;
        return acc;
      }, {});

      const requestData = {
        ...form,
        ...formattedProducts,
      };

      const method = editId ? "PUT" : "POST";
      const url = editId
        ? `${INVOICE_CRUD_API}?id=${editId}`
        : INVOICE_CRUD_API;

      const response = await axios({
        method,
        url,
        data: requestData,
      });

      setMessage(response.data.message || "Invoice saved successfully");
      resetForm();
      fetchInvoices(); // Refresh the list of invoices after adding/updating
    } catch (error) {
      console.error("Error details:", error.response || error.message || error);
      setMessage(
        error.response?.data?.message ||
          "Error saving invoice. Please check the console for details."
      );
    }
  };

  const resetForm = () => {
    setForm({
      invoice_number: "",
      invoice_date: today,
      due_date: today,
      customers_name: "",
      customers_email: "",
      customers_phone: "",
      customers_address: "",
      products: [],
      discount: 0,
      total_amount: 0,
      GSTAmount: 0,
      status: "Paid",
    });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "1rem", // Ensure the control has a fixed height
      display: "flex",
      alignItems: "center", // Centers items vertically
      padding: "0 0.5rem", // Adds some horizontal padding
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "100%", // Ensures the value container fills the control
      display: "flex",
      alignItems: "center", // Centers the text vertically
      padding: "0", // Removes any extra padding
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
      display: "flex",
      alignItems: "center", // Ensures the text is vertically centered
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
      display: "flex",
      alignItems: "center", // Centers the input text
    }),
    placeholder: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
      display: "flex",
      alignItems: "center", // Centers the placeholder text
    }),
    indicatorSeparator: () => ({
      display: "none", // Hides the indicator separator
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0.5rem", // Adjust padding for the dropdown indicator
    }),
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  useEffect(() => {
    // Filter invoices based on date range
    const filtered = invoices.filter((invoice) => {
      const invoiceDate = new Date(invoice.invoice_date);
      const start = startDate
        ? new Date(startDate)
        : new Date(-8640000000000000); // Min date
      const end = endDate ? new Date(endDate) : new Date(8640000000000000); // Max date
      const matchesPhone = phoneFilter
      ? invoice.customers_phone?.includes(phoneFilter)
      : true;
      return invoiceDate >= start && invoiceDate <= end && matchesPhone;
    });
    setFilteredInvoices(filtered);
  }, [startDate, endDate,phoneFilter, invoices]);

  const groupedProductData = productData.reduce((acc, product) => {
    // Group products by category
    if (!acc[product.category]) {
      acc[product.category] = [];
    }
    acc[product.category].push(product);
    return acc;
  }, {});

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(SETTINGS_CRUD_API);
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };
    fetchSettings();
  }, []);

  const fetchInvoices = async () => {
    try {
      // Fetch settings
      const settingsResponse = await axios.get(SETTINGS_CRUD_API);
      const settings = settingsResponse.data;

      // Extract the prefix from settings
      const prefix = settings.invoice_prefix || "";

      // Fetch invoices
      const invoiceResponse = await axios.get(INVOICE_CRUD_API);
      setInvoices(invoiceResponse.data);

      // Generate next invoice number
      if (invoiceResponse.data.length > 0) {
        const lastInvoiceNumber =
          invoiceResponse.data[invoiceResponse.data.length - 1].invoice_number;
        const lastInvoiceDigits = parseInt(
          lastInvoiceNumber.replace(settings.invoice_number, ""),
          10
        );
        const nextInvoiceDigits = (lastInvoiceDigits + 1)
          .toString()
          .padStart(5, "0");
        const nextInvoiceNumber = `${settings.invoice_number}${nextInvoiceDigits}`;

        setForm((prevForm) => ({
          ...prevForm,
          invoice_number: nextInvoiceNumber,
        }));
      } else {
        setForm((prevForm) => ({
          ...prevForm,
          invoice_number: `${settings.invoice_number}000001`,
        }));
      }
    } catch (error) {
      console.error("Error fetching invoices or settings:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this invoice?")) {
      try {
        await axios.delete(`${INVOICE_CRUD_API}?id=${id}`);
        fetchInvoices();
      } catch (error) {
        console.error("Error deleting invoice:", error);
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // Format as DD-MM-YYYY
  };

  const refreshData = () => {
    fetchInvoices();
  };
  const handleStatusChange = async (id, newStatus) => {
    try {
      const response = await fetch(`${INVOICE_CRUD_API}?id=${id}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ status: newStatus }),
      });

      if (response.ok) {
        alert("Status updated successfully!");
        refreshData();
      } else {
        alert("Failed to update status.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  // Table columns definition
  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div className="text-[10px] font-bold">Invoice Number</div>
        ),
        accessor: "invoice_number",
        Cell: ({ row }) => (
          <div>
            <div className="text-[10px] font-medium">
              {row.original.invoice_number}
            </div>
            <div className="flex items-center space-x-2">
              <Link to={`/invoice/${row.original.id}`}>
                <div className="text-[14px] sm:text-sm w-5 h-5 sm:w-10 sm:h-10 bg-violet-700 text-white rounded-md hover:bg-blue-600 flex items-center justify-center">
                  <IoEyeOutline />
                </div>
              </Link>
              <div
                onClick={() => handleDelete(row.original.id)}
                className="text-[14px] sm:text-sm w-5 h-5 sm:w-10 sm:h-10 bg-red-700 text-white rounded-md hover:bg-red-600 flex items-center justify-center"
              >
                <IoTrashSharp />
              </div>
            </div>
          </div>
        ),
      },
      {
        Header: () => <div className="text-[10px] font-bold">Invoice Date</div>,
        accessor: "invoice_date",
        Cell: ({ value }) => (
          <div className="text-[10px] sm:text-sm">{formatDate(value)}</div>
        ),
      },
      {
        Header: () => (
          <div className="text-[10px] font-bold">Customer Name</div>
        ),
        accessor: "customers_name",
        Cell: ({ row }) => (
          <div
            className="text-[10px] sm:text-sm tracking-tighter leading-tight overflow-hidden text-ellipsis"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              lineClamp: 2,
            }}
          >
            <div>{row.original.customers_name}</div>
            <div className="text-[10px] sm:text-sm text-gray-500">
              {row.original.customers_phone}
            </div>
          </div>
        ),
      },

      {
        Header: () => (
          <div className="text-[10px] sm:text-sm font-bold">Total Amount</div>
        ),
        accessor: "total_amount",
        Cell: ({ row }) => (
          <div>
            <div className="text-[10px] sm:text-sm font-medium">
              {row.original.total_amount} ₹
            </div>
            <select
              className={`pl-1 py-1 w-15 rounded-lg text-white text-[10px] sm:text-xm ${
                row.original.status === "Paid"
                  ? "bg-green-500"
                  : row.original.status === "Pending"
                  ? "bg-yellow-500"
                  : "bg-red-600"
              }`}
              value={row.original.status}
              onChange={(e) => {
                handleStatusChange(row.original.id, e.target.value);
              }}
            >
              <option value="Paid">Paid</option>
              <option value="Unpaid">Unpaid</option>
              <option value="Pending">Pending</option>
            </select>
          </div>
        ),
      },
    ],
    []
  );

  // Table setup
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: filteredInvoices,
      initialState: {
        pageIndex: 0,
        pageSize: 15,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className="mx-auto mt-5 mb-14">
      <h1 className="text-2xl font-bold  text-center">Invoices</h1>
      <p className="text-sm text-center mb-4">Start Creating your invoice.</p>
      {message && (
        <div className="mb-4 p-4 text-white bg-violet-500 rounded-md">
          {message}
        </div>
      )}
      <form onSubmit={handleAddOrUpdate} className="p-4 bg-gray-200 rounded-lg">
        <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
          {/* Left Column */}
          <div className="mb-4">
            <p className=" text-gray-700 mt-2 text-[12px]">
              Invoice Number{" "}
              <p className="text-black font-bold text-[12px]">
                {form.invoice_number}
              </p>
            </p>

            <label
              htmlFor="invoice_date"
              className="block text-[12px] font-medium text-gray-700 mt-2"
            >
              Invoice Date
            </label>
            <input
              type="date"
              id="invoice_date"
              value={form.invoice_date}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-[12px]"
              required
            />

            <label
              htmlFor="due_date"
              className="block font-medium text-gray-700 mt-2 text-[12px]"
            >
              Due Date
            </label>
            <input
              type="date"
              id="due_date"
              value={form.due_date}
              onChange={handleInputChange}
              className="block w-full border text-[12px] border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>

          {/* Right Column */}
          <div className="mb-4">
            <label
              htmlFor="customers_name"
              className="block text-[12px] font-medium text-gray-700"
            >
              Customer's Name or Phone Number
            </label>
            <Select
              id="customers_name"
              value={
                customerData.find(
                  (customer) =>
                    customer.name === form.customers_name ||
                    customer.phone === form.customers_phone
                )
                  ? {
                      value: form.customers_name || form.customers_phone,
                      label: form.customers_name || form.customers_phone,
                    }
                  : null
              }
              onChange={(selected) =>
                handleCustomerNameChange({
                  target: { value: selected ? selected.value : "" },
                })
              }
              options={customerData.map((customer) => ({
                value: customer.name, // Store name as the value
                label: `${customer.name} - ${customer.phone}`, // Combine name and phone for the display
              }))}
              className="mt-1 block w-full text-[12px]"
              styles={customStyles} // Apply custom styles
            />
            <p className="text-[12px] text-gray-500 mt-2">
              Email: {form.customers_email}
            </p>
            <p className="text-[12px] text-gray-500">
              Phone: {form.customers_phone}
            </p>
            <p className="text-[12px] text-gray-500">
              Address: {form.customers_address}
            </p>
            <Link
              to="/clients"
              className="text-violet-600 text-[12px] font-bold mt-1"
            >
              + Add New Customer
            </Link>
          </div>
        </div>

        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Products</h2>
          {form.products.map((product, index) => (
            <div key={index} className="grid grid-cols-3 gap-4 mb-2">
              <div>
                <label
                  htmlFor={`product_name_${index}`}
                  className="block text-[12px] font-medium text-gray-700"
                >
                  Product Name
                </label>
                <Select
                  className="text-[12px] "
                  id={`product_name_${index}`}
                  value={{
                    value: product.name,
                    label: `${product.name} - Price: ${product.price}`,
                  }}
                  onChange={(selectedOption) =>
                    handleProductChange(index, {
                      target: { name: "name", value: selectedOption.value },
                    })
                  }
                  options={Object.keys(groupedProductData).map((category) => ({
                    label: `----- ${category} -----`,
                    options: groupedProductData[category].map((product) => ({
                      value: product.name,
                      label: `${product.name} - Price: ${product.price}`,
                    })),
                  }))}
                  placeholder="Select Product"
                  isSearchable
                  styles={customStyles} // Apply the custom styles here
                />
                <p className="text-[12px] text-gray-500">
                  Price: {product.price}
                </p>
                <p className="text-[12px] text-gray-500">
                  Category: {product.category}
                </p>
                <p className="text-[12px] text-gray-500">
                  Description: {product.description}
                </p>
              </div>

              {/* Product Price Field */}
              <div>
                <label
                  htmlFor={`product_price_${index}`}
                  className="block text-[12px] font-medium text-gray-700"
                >
                  Price
                </label>
                <input
                  type="number"
                  id={`product_price_${index}`}
                  name="price"
                  value={product.price}
                  onChange={(e) => handleProductChange(index, e)}
                  className="mt-1 text-[12px] block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  min="1"
                />

                <label
                  htmlFor={`product_description_${index}`}
                  className="block text-[12px] mt-2 font-medium text-gray-700"
                >
                  Description
                </label>
                <input
                  type="text"
                  id={`product_description_${index}`}
                  name="description"
                  value={product.description}
                  onChange={(e) => handleProductChange(index, e)}
                  className="mt-1 block w-full border text-[12px] border-gray-300 rounded-md shadow-sm p-3 focus:border-violet-500 focus:ring focus:ring-violet-200 focus:ring-opacity-50"
                  min="1"
                />
              </div>

              {/* Product Quantity Field */}
              <div>
                <label
                  htmlFor={`product_quantity_${index}`}
                  className="block text-[12px] font-medium text-gray-700"
                >
                  Quantity
                </label>
                <input
                  type="number"
                  id={`product_quantity_${index}`}
                  name="quantity"
                  value={product.quantity}
                  onChange={(e) => handleProductChange(index, e)}
                  className="mt-1 block text-[12px] w-full border border-gray-300 rounded-md shadow-sm p-2"
                  min="1"
                />
              </div>
            </div>
          ))}

          <button
            type="button"
            onClick={handleAddProduct}
            className="mt-2 px-2 py-2 text-[12px] bg-violet-600 text-white rounded-md flex items-center justify-center space-x-2"
          >
            <IoCart className="text-[14px]" />
            <span>Add Product</span>
          </button>
        </div>

        <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
          <div className="">
            <label
              htmlFor="discount"
              className="block text-[12px] font-medium text-gray-700"
            >
              Discount
            </label>
            <input
              type="number"
              id="discount"
              value={form.discount}
              onChange={handleInputChange}
              className="mt-1 text-[12px] block w-full border border-gray-300 rounded-md shadow-sm p-2"
              min="0"
            />
          </div>
          <div className="">
            <label
              htmlFor="total_amount"
              className="block text-[12px] font-medium text-gray-700"
            >
              Total Amount
            </label>
            <input
              type="text"
              id="total_amount"
              value={form.total_amount}
              onChange={handleInputChange}
              className="mt-1 text-[12px] block w-full border border-gray-300 rounded-md shadow-sm p-2"
              min="0"
            />
          </div>
          <div>
            <label
              htmlFor="GSTAmount"
              className="block text-[12px] font-medium text-gray-700"
            >
              Tax ({parseFloat(settings.Tax)}%)
            </label>
            <input
              type="text"
              id="GSTAmount"
              value={form.GSTAmount}
              onChange={handleInputChange}
              className="mt-1 text-[12px] block w-full border border-gray-300 rounded-md shadow-sm p-2 bg-gray-200"
              min="0"
              readOnly
            />
          </div>

          <div className="">
            <label
              htmlFor="status"
              className="block text-[12px] font-medium text-gray-700"
            >
              Status
            </label>
            <select
              id="status"
              value={form.status}
              onChange={handleInputChange}
              className="mt-1 block text-[12px] w-full border border-gray-300 rounded-md shadow-sm p-2"
            >
              <option value="">Select Status</option>
              <option value="Paid">Paid</option>
              <option value="Pending">Pending</option>
              <option value="Unpaid">Unpaid</option>
            </select>
          </div>
        </div>

        <div className="flex space-x-4">
          <button
            type="submit"
            className="px-4 py-2 bg-black text-white rounded-md"
          >
            {editId ? "Update Invoice" : "Add Invoice"}
          </button>
        </div>
      </form>

      <div className="w-full overflow-x-hidden mt-5">
        <div className="mb-4 px-2">
          <p className="text-[18px] text-center p-2">
            Search invoice
          </p>
          <div className="flex items-center space-x-2 justify-center">
            <label className="text-[12px] font-medium text-gray-700">
              Start Date:
            </label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="text-[12px] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500"
            />
            <label className="text-[12px] font-medium text-gray-700">
              End Date:
            </label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="text-[12px] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500"
            />
          </div>


          <div className="flex items-center justify-between mt-4">
            <label className="text-sm font-medium text-gray-700">Phone Number:</label>
            <input
              type="text"
              placeholder="Filter by Phone Number"
              value={phoneFilter}
              onChange={(e) => setPhoneFilter(e.target.value)}
              className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500"
            />
            </div>
          <div className="mt-2">
            <button
              onClick={() => {
                setStartDate("");
                setEndDate(today);
                setPhoneFilter("");
              }}
              className="text-[12px] bg-violet-600 text-white p-2 rounded-md hover:bg-violet-700 transition duration-200"
            >
              Clear Filter
            </button>
          </div>
        </div>

        <div className="overflow-hidden border border-gray-200 rounded-md">
          <table
            {...getTableProps()}
            className="w-full table-auto divide-y divide-gray-200 bg-white"
          >
            <thead className="bg-gray-100">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="px-2 py-1 text-left text-[10px] font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {column.render("Header")}
                      <span className="text-gray-400 ml-1">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} />
                          ) : (
                            <FontAwesomeIcon icon={faSortUp} />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className="divide-y divide-gray-200"
            >
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-2 py-2 text-[10px] text-gray-900 truncate"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="mt-4 space-y-2">
          <div className="text-sm text-center text-gray-600">
            Page {pageIndex + 1} of {pageOptions.length}
          </div>

          <div className="flex items-center space-x-2 justify-center">
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="bg-violet-700 text-white px-3 py-1 rounded-md hover:bg-violet-400 transition duration-200"
            >
              Previous
            </button>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="bg-violet-700 text-white px-3 py-1 rounded-md hover:bg-violet-400 transition duration-200"
            >
              Next
            </button>
          </div>

          <div className="text-sm text-gray-600 text-center">
            Go to page:{" "}
            <input
              type="number"
              min="1"
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              className="w-16 p-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicesPage;
