import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import { IoEyeOutline } from "react-icons/io5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { INVOICE_CRUD_API } from "../../hooks/APIHooks";

const GrossProfit = () => {
  const [invoices, setInvoices] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(today);
  const [phoneFilter, setPhoneFilter] = useState("");
  const [filteredInvoices, setFilteredInvoices] = useState([]);

  useEffect(() => {
    fetchInvoices();
  }, []);

  useEffect(() => {
    const filtered = invoices.filter((invoice) => {
      const invoiceDate = new Date(invoice.invoice_date);
      const start = startDate
        ? new Date(startDate)
        : new Date(-8640000000000000); // Min date
      const end = endDate ? new Date(endDate) : new Date(8640000000000000); // Max date
      const matchesPhone = phoneFilter
        ? invoice.customers_phone?.includes(phoneFilter)
        : true;
      return invoiceDate >= start && invoiceDate <= end && matchesPhone;
    });
    setFilteredInvoices(filtered);
  }, [startDate, endDate, phoneFilter, invoices]);

  const fetchInvoices = async () => {
    try {
      const invoiceResponse = await axios.get(INVOICE_CRUD_API);
      setInvoices(invoiceResponse.data);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  const calculateGrossProfit = () => {
    const totalRevenue = filteredInvoices
      .filter((invoice) => invoice.status === "Paid")
      .reduce((sum, invoice) => sum + parseFloat(invoice.total_amount || 0), 0);
    return totalRevenue;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB");
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div className="text-[10px] font-bold">Invoice Number</div>
        ),
        accessor: "invoice_number",
        Cell: ({ row }) => (
          <div>
            <div>{row.original.invoice_number}</div>
            <Link to={`/invoice/${row.original.id}`}>
              <button className="bg-violet-900 mt-2 text-center text-white p-2 rounded-md hover:bg-blue-600 transition duration-200 flex justify-center">
                <IoEyeOutline className="my-auto" />
                <span className="ml-1">View</span>
              </button>
            </Link>
          </div>
        ),
      },
      {
        Header: () => <div className="text-[10px] font-bold">Invoice Date</div>,
        accessor: "invoice_date",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: () => (
          <div className="text-[10px] font-bold">Customer Name</div>
        ),
        accessor: "customers_name",
        Cell: ({ row }) => (
          <div>
            <div>{row.original.customers_name}</div>
            <div className="text-sm text-gray-500">
              {row.original.customers_phone}
            </div>
          </div>
        ),
      },
      {
       Header: () => (
          <div className="text-[10px] sm:text-sm font-bold">Total Amount</div>
        ),
        accessor: "total_amount",
        Cell: ({ row }) => (
          <div>
            <div className="text-[12px]">{row.original.total_amount} ₹</div>
            <span
              className={`px-2 py-1 mt-2 rounded-full text-white ${
                row.original.status === "Paid" ? "bg-green-500" : "bg-red-500"
              }`}
            >
              {row.original.status}
            </span>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: filteredInvoices,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className="max-w-4xl mx-auto mt-5 mb-14">
      <h1 className="text-2xl font-bold text-center">Gross Profit</h1>
      <p className="text-sm text-center">Total Profit Generated on your Invoice.</p>
      <div className="bg-green-600 text-white text-xl font-bold text-center mb-4">
        Gross Profit: {calculateGrossProfit()} INR
      </div>

      <div className="mx-auto overflow-x-auto">
        <div className="mb-4 px-2">
          <div className="flex items-center justify-between">
            <label className="text-sm font-medium text-gray-700">Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500"
            />
            <label className="text-sm font-medium text-gray-700">End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500"
            />
          </div>
          <div className="flex items-center justify-between mt-4">
            <label className="text-sm font-medium text-gray-700">Phone Number:</label>
            <input
              type="text"
              placeholder="Filter by Phone Number"
              value={phoneFilter}
              onChange={(e) => setPhoneFilter(e.target.value)}
              className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500"
            />
          </div>
          <button
            onClick={() => {
              setStartDate("");
              setEndDate(today);
              setPhoneFilter("");
            }}
            className="bg-violet-600 mt-2 text-white p-2 rounded-md hover:bg-violet-700 transition duration-200"
          >
            Clear Filter
          </button>
        </div>

        <table
          {...getTableProps()}
          className="min-w-full divide-y divide-gray-200 bg-white shadow-md rounded-md"
        >
          <thead className="bg-gray-100">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {column.render("Header")}
                    <span className="text-gray-400 ml-1">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="px-4 py-3 text-sm text-gray-900"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="mt-4 space-y-2">
          <div className="text-sm text-center text-gray-600">
            Page {pageIndex + 1} of {pageOptions.length}
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="bg-gray-300 text-gray-700 p-2 rounded-md hover:bg-gray-400 transition duration-200"
            >
              Previous
            </button>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="bg-gray-300 text-gray-700 p-2 rounded-md hover:bg-gray-400 transition duration-200"
            >
              Next
            </button>
          </div>
          <div className="text-sm text-gray-600 text-center">
            Go to page:{" "}
            <input
              type="number"
              min="1"
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={(e) => gotoPage(Number(e.target.value) - 1)}
              className="w-16 p-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrossProfit;
