import React, { useEffect, useState, forwardRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import ReactToPrint from "react-to-print";
import { IoPrint, IoMailOutline } from "react-icons/io5"; // Import IoPrint icon
import { FaWhatsapp } from "react-icons/fa"; // Import WhatsApp icon

import {
  INVOICE_CRUD_API,
  SETTINGS_CRUD_API,
  EMAIL_API,
} from "../../hooks/APIHooks";

// Component to be printed
const PrintableInvoice = forwardRef((props, ref) => {
  const { invoice } = props;
  const [companySettings, setCompanySettings] = useState({});
  const [settings, setSettings] = useState({
    companylogo: "",
    companyName: "",
    companyPhone: "",
    companyPhone2: "",
    companyEmail: "",
    companyAddress: "",
    GSTnumber: "",
    Tax: "",
  });

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);
  };

  const products = invoice?.products ? JSON.parse(invoice.products) : [];

  const calculateProductTax = (price, quantity) => {
    return (price * quantity * gstRate) / (100 + gstRate);
  };

  // Calculate subtotal, discount, and total
  const subtotal = products.reduce(
    (acc, product) =>
      acc +
      (parseFloat(product.price) || 0) * (parseFloat(product.quantity) || 0),
    0
  );
  const discount = parseFloat(invoice?.discount) || 0;
  const total = subtotal - discount;

  const gstRate = parseFloat(settings.Tax); // Assuming the Tax setting holds the Excluded GST percentage
  const grandTotal = total;

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(SETTINGS_CRUD_API);
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };
    fetchSettings();
  }, []);

  return (
    <div ref={ref} className="a4 mx-auto p-2 bg-white">
      {/* Header Section */}
      <h1 className="text-[10px] text-center font-bold text-gray-800">
        {settings.companyName}
      </h1>
      <h1 className="text-[10px] text-center mx-auto text-gray-800 w-1/2">
        {settings.companyAddress}
      </h1>
      <h1 className="text-[10px] text-center mx-auto text-gray-800 w-1/2">
        {settings.GSTnumber}
      </h1>

      <div className="flex justify-between items-center mb-6">
        <div>
          <img
            src={settings.companylogo}
            alt="Company Logo"
            className="w-16 h-auto mx-auto object-cover"
          />
          <h1 className="text-[14px] sm:text-3xl font-bold text-gray-800">
            Invoice
          </h1>
        </div>
        <div className="text-gray-600 text-[12px] sm:text-sm">
          <div>
            Invoice Number: <strong>{invoice?.invoice_number || "N/A"}</strong>
          </div>
          <div>
            Invoice Date: <strong>{formatDate(invoice?.invoice_date)}</strong>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-300 mb-6"></div>

      {/* Customer and Invoice Details Section */}
      <div className="grid grid-cols-2 md:grid-cols-2 gap-6 mb-6">
        <div className="bg-gray-100 p-4 rounded-md shadow-sm">
          <h2 className="text-[14px] font-semibold mb-2">Customer Details</h2>
          <div className="text-[14px]">
            <strong>Name:</strong> {invoice?.customers_name || "N/A"}
          </div>
          <div className="text-[14px]">
            <strong>Email:</strong> {invoice?.customers_email || "N/A"}
          </div>
          <div className="text-[14px]">
            <strong>Phone:</strong> {invoice?.customers_phone || "N/A"}
          </div>
          <div className="text-[14px]">
            <strong>Address:</strong> {invoice?.customers_address || "N/A"}
          </div>
        </div>
        <div className="bg-gray-100 p-4 rounded-md shadow-sm">
          <h2 className="text-[14px] font-semibold mb-2">Invoice Details</h2>
          <div className="text-[14px]">
            <strong>Due Date:</strong> {formatDate(invoice?.due_date) || "N/A"}
          </div>
          <div className="text-[14px]">
            <strong>Discount:</strong> {invoice?.discount || "N/A"}
          </div>
          <div className="text-[14px]">
            <strong>Total Amount:</strong>₹ {grandTotal.toFixed(2)}
          </div>
          <div className="text-[14px]">
            <strong>
              Status:
              <a
                className={`text-[14px] ${
                  invoice?.status === "Pending" ? "text-red-500" : ""
                }`}
              >
                {" "}
                {invoice?.status || "N/A"}
              </a>
            </strong>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-300 mb-6"></div>

      {/* Billing Section */}
      <div className="bg-gray-100 p-4 rounded-md shadow-sm overflow-hidden h-screen flex flex-col">
  <h2 className="text-[12px] font-semibold mb-2">Billing</h2>
  <div className="overflow-x-auto flex-grow">
    <table className="min-w-full bg-white border border-gray-300">
      <thead>
        <tr>
          <th className="py-1 px-2 border-b text-[10px] sm:text-[12px] text-left">
            Products
          </th>
          <th className="py-1 px-2 border-b text-[10px] sm:text-[12px] text-center">
            Quantity
          </th>
          <th className="py-1 px-2 border-b text-[10px] sm:text-[12px] text-center">
            Price
          </th>
          <th className="py-1 px-2 border-b text-[10px] sm:text-[12px] text-center">
            Tax ({parseFloat(settings.Tax)}%)
          </th>
          <th className="py-1 px-2 border-b text-[10px] sm:text-[12px] text-right">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {products.length > 0 ? (
          products.map((product, index) => {
            const productTax = calculateProductTax(
              parseFloat(product.price) || 0,
              parseFloat(product.quantity) || 0
            );
            return (
              <tr key={index}>
                <td className="py-2 text-[12px] sm:text-[14px] px-4 border-b">
                  <div className="tracking-tight leading-tight">
                    <span className="text-[8px] sm:text-[10px] font-bold">
                      {product.category} -
                    </span>
                    <span className="text-[8px] sm:text-[10px] font-bold">
                      {product.name}
                    </span>{" "}
                    <br />
                    <span className="text-[8px] sm:text-[10px]">
                      {product.description}
                    </span>
                  </div>
                </td>
                <td className="py-2 text-[12px] sm:text-[14px] px-4 text-center border-b">
                  {product.quantity || "N/A"}
                </td>
                <td className="py-2 text-[12px] sm:text-[14px] px-4 text-center border-b">
                  ₹{parseFloat(product.price).toFixed(2) || "N/A"}
                </td>
                <td className="py-2 text-[12px] sm:text-[14px] px-4 text-center border-b">
                  ₹{productTax.toFixed(2)}
                </td>
                <td className="py-2 text-[12px] sm:text-[14px] px-4 text-right border-b">
                  ₹{(product.price * product.quantity).toFixed(2)}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="5" className="py-2 px-4 text-center">
              No products available
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="4" className="text-right px-2 text-[12px] sm:text-[14px]">
            Subtotal:
          </td>
          <td className="text-right px-2 text-[12px] sm:text-[14px]">
            ₹ {subtotal.toFixed(2)}
          </td>
        </tr>
        <tr>
          <td
            colSpan="4"
            className="text-right px-2 text-[12px] sm:text-[14px] font-bold"
          >
            Discount:
          </td>
          <td className="text-right px-2 text-[12px] sm:text-[14px]">
            ₹ {discount.toFixed(2)}
          </td>
        </tr>
        <tr>
          <td
            colSpan="4"
            className="text-right px-2 text-[12px] sm:text-[14px] font-bold"
          >
            Total:
          </td>
          <td className="text-right px-2 text-[12px] sm:text-[14px]">
            ₹ {total.toFixed(2)}
          </td>
        </tr>
        <tr>
          <td colSpan="4" className="text-right px-2 text-[12px] sm:text-[14px]">
            GST:
          </td>
          <td className="text-right px-2 text-[12px] sm:text-[14px]">
            ₹ {invoice.GSTAmount}
          </td>
        </tr>
        <tr>
          <td
            colSpan="4"
            className="text-right px-2 text-[14px] sm:text-[16px] font-bold"
          >
            Grand Total:
          </td>
          <td className="text-right px-2 text-[14px] sm:text-[16px]">
            ₹ {grandTotal.toFixed(2)}
          </td>
        </tr>
      </tfoot>
    </table>

    <div className="flex pt-2 flex-wrap">
      <div className="w-full sm:w-1/2 bg-gray-100 border border-gray-300 rounded-md p-2">
        <p className="text-black text-[8px] sm:text-[10px]">
          Dear Valued Client,
          <br />
          <ul className="list-disc list-inside">
            <li>
              We strive to ensure a smooth and secure transaction process for
              all our customers. Please follow these safety guidelines upon
              receiving your invoice.
            </li>
            <li>
              Inspect Your Invoice: Carefully review the invoice upon receipt.
              Ensure that all items, services, and prices are accurate. If you
              notice any discrepancies, report them immediately.
            </li>
            <li>
              Report Any Issues: If you detect any errors or suspicious
              activity related to the invoice or payment, contact our support
              team right away. We are dedicated to resolving any concerns and
              safeguarding your financial transactions.
            </li>
          </ul>
        </p>
      </div>
      <div className="w-full sm:w-1/2 bg-gray-100 border border-gray-300 rounded-md p-2 text-right">
        {invoice?.status === "Pending" && (
          <Link to={`/invoice/${invoice?.id}/paymentgateway`}>
            <button className="bg-blue-500 text-white rounded-md p-2">
              Pay Now
            </button>
          </Link>
        )}
      </div>
    </div>

    <p className="text-black text-[8px] sm:text-[10px] pt-2">
      All disputes are subject to Visakhapatnam locality only.
    </p>
    <p className="text-black text-[8px] sm:text-[10px]">
      <strong>Note:</strong> This is a computer generated invoice & does not
      require a signature.
    </p>
    <p className="bg-gray-500 text-white px-2 text-[8px] sm:text-[10px] mt-2 text-center">
      Invoice from {settings.companyName}
    </p>

    <div className="text-center mt-2">
      <p className="text-[8px] sm:text-[10px] font-bold text-gray-800">
        Thank you! Please do visit us again! :)
      </p>
    </div>
  </div>
</div>

    </div>
  );
});

const FullInvoice = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const componentRef = React.useRef();

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        console.log("Fetching invoice with ID:", id);
        const response = await axios.get(`${INVOICE_CRUD_API}?id=${id}`);
        console.log("API Response:", response.data);

        if (response.data && response.data.length > 0) {
          setInvoice(response.data[0]);
        } else {
          setError("No data found");
        }
      } catch (error) {
        console.error(
          "API Error:",
          error.response ? error.response.data : error.message
        );
        setError("Error fetching invoice details");
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [id]);

  const handleWhatsAppShare = () => {
    const customerName = encodeURIComponent(invoice?.customers_name || "");
    const customerPhone = encodeURIComponent(
      (invoice?.customers_phone || "").replace(/\s+/g, "").replace("+", "")
    );
    const whatsappUrl = `https://wa.me/${customerPhone}?text=Hello,%20${customerName}%2C%20here%27s%20your%20invoice%20link:%20${window.location.href}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleSendEmail = async () => {
    try {
      const response = await axios.post(EMAIL_API, {
        email: invoice.customers_email,
        invoiceId: invoice.id,
      });
      console.log("Response Data:", response.data);
      if (response.data.status === "success") {
        alert("Invoice sent successfully!");
      } else {
        alert(`Successfully Sent: ${response.data.message || "Unknown"}`);
      }
    } catch (error) {
      console.error("Error sending invoice:", error);
      alert("Failed to send the invoice.");
    }
  };

  if (loading) return <div className="text-center text-lg">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="a4-container">
      <div className="max-w-a4 mx-auto">
        <div className="my-5  w-full">
          <div className="flex justify-center space-x-2">
            <div>
              <ReactToPrint
                trigger={() => (
                  <button className="bg-blue-500 text-white text-[12px] text-center px-4 py-2 rounded shadow-lg flex items-center">
                    <IoPrint className="mr-2" /> Print as PDF
                  </button>
                )}
                content={() => componentRef.current}
                documentTitle={`CSD Invoice - ${invoice?.invoice_number}`} // Add this line
              />
            </div>

            <div>
              <button
                onClick={handleWhatsAppShare}
                className="bg-green-500 text-white px-4 py-2 text-[12px] text-center rounded shadow-lg flex items-center"
              >
                <FaWhatsapp className="mr-2" />
                WhatsApp
              </button>
            </div>

            <div>
              <button
                className="bg-red-500 text-white py-2 rounded text-[12px] text-center md:text-sm shadow-lg flex items-center"
                onClick={handleSendEmail}
              >
                <IoMailOutline className="mr-2" />
                Send Email
              </button>
            </div>
          </div>
        </div>

        <PrintableInvoice ref={componentRef} invoice={invoice} />
      </div>
    </div>
  );
};

export default FullInvoice;
