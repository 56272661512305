import React, { useState, useEffect } from "react";
import axios from "axios";
import { PRODUCTS_CRUD_API } from "../hooks/APIHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

const MenuPage = () => {
  const [products, setProducts] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(PRODUCTS_CRUD_API);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleSort = (field) => {
    const direction =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(direction);

    const sortedProducts = [...products].sort((a, b) => {
      if (direction === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    setProducts(sortedProducts);
  };

  const getSortIcon = (field) => {
    if (sortField === field) {
      return sortDirection === "asc" ? faSortUp : faSortDown;
    } else {
      return faSort;
    }
  };

  // Group products by category
  const groupedProducts = products.reduce((acc, product) => {
    if (!acc[product.category]) {
      acc[product.category] = [];
    }
    acc[product.category].push(product);
    return acc;
  }, {});

  return (
    <div className="p-4 bg-gray-50 min-h-screen mb-10">
      <h1 className="text-3xl font-bold text-gray-800">Products</h1>
      <p className="text-sm text-black mb-4">Viewing all available products.</p>

      {Object.keys(groupedProducts).map((category) => (
        <div key={category} className="mb-8">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">{category}</h3>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse bg-white text-[12px]">
              <thead>
                <tr className="bg-gray-100">
                  <th
                    className="p-2 border border-gray-300 cursor-pointer"
                    onClick={() => handleSort("category")}
                  >
                    Category <FontAwesomeIcon icon={getSortIcon("category")} />
                  </th>
                  <th
                    className="p-2 border border-gray-300 cursor-pointer"
                    onClick={() => handleSort("name")}
                  >
                    Name <FontAwesomeIcon icon={getSortIcon("name")} />
                  </th>
                  <th
                    className="p-2 border border-gray-300 cursor-pointer"
                    onClick={() => handleSort("price")}
                  >
                    Price <FontAwesomeIcon icon={getSortIcon("price")} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {groupedProducts[category].map((product) => (
                  <tr key={product.id} className="hover:bg-gray-100">
                    <td className="p-2 border border-gray-300 text-[12px]">
                      {product.category}
                    </td>
                    <td className="p-2 border border-gray-300 text-[12px]">
                      {product.name}
                    </td>
                    <td className="p-2 border border-gray-300 text-[12px]">
                      ₹{product.price}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MenuPage;
