import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { INVOICE_CRUD_API } from "../../hooks/APIHooks";
import axios from "axios";

const PaymentPage = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [amount, setAmount] = useState(""); // Initialize as an empty string

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        console.log("Fetching invoice with ID:", id);
        const response = await axios.get(`${INVOICE_CRUD_API}?id=${id}`);
        console.log("API Response:", response.data);

        if (response.data && response.data.length > 0) {
          setInvoice(response.data[0]);
          setAmount(response.data[0].total_amount); // Set amount from fetched invoice
        } else {
          setError("No data found");
        }
      } catch (error) {
        console.error(
          "API Error:",
          error.response ? error.response.data : error.message
        );
        setError("Error fetching invoice details");
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [id]);

  const handlePayment = async () => {
    if (!amount || isNaN(amount) || amount <= 0) {
      alert("Please enter a valid amount.");
      return;
    }

    console.log("Amount to be sent to backend:", amount * 100); // Log the amount being sent in paisa

    try {
      const response = await fetch(
        "https://invoice.cyberspacedigital.in/apis/PaymentGateway/create-order.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: amount * 100, // Amount in paise
            invoice_number: invoice?.invoice_number, // Pass the invoice number
          }),
        }
      );

      const data = await response.json();
      console.log("Response from backend:", data);

      if (!data.id || data.error) {
        alert("Error creating Razorpay order");
        console.error(data.error || "Unknown error");
        return;
      }

      const options = {
        key: "rzp_live_K7PipKGoIY7p0n",
        amount: data.amount,
        currency: "INR",
        order_id: data.id,
        name: "Cyber Space Digital",
        description: "Invoice Payment",
        image: "https://your-logo-url.com/logo.png",
        handler: function (response) {
          console.log("Payment Response: ", response);

          const verificationData = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            invoice_number: invoice?.invoice_number,
          };

          console.log("Data sent for verification:", verificationData);

          fetch(
            "https://invoice.cyberspacedigital.in/apis/PaymentGateway/verify-payment.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(verificationData),
            }
          )
            .then((res) => res.json())
            .then((resData) => {
              console.log("Verification Response: ", resData);

              if (resData.success) {
                alert("Payment Successful!");
              } else {
                alert("Payment Verification Failed");
              }
            });
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
      alert("An error occurred while creating the order. Please try again.");
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6 space-y-6">
        <h1 className="text-2xl font-semibold text-center text-gray-800">
          Payment Page
        </h1>

        {/* Display Invoice Details */}
        <div className="text-gray-600 text-[12px] sm:text-sm">
          <div>
            ID: <strong>{invoice?.id || "N/A"}</strong>
          </div>
          <div>
            Invoice Number: <strong>{invoice?.invoice_number || "N/A"}</strong>
          </div>
          <div>
            Total Amount: <strong>₹{invoice?.total_amount || "N/A"}</strong>
          </div>
        </div>

        {/* Display Amount */}
        <div>
          <label htmlFor="amount" className="block text-gray-700 font-medium">
            Amount
          </label>
          <input
            type="text"
            id="amount"
            value={amount || ""}
            readOnly
            className="w-full mt-2 p-3 border border-gray-300 rounded-md text-gray-600 bg-gray-100"
            disabled
          />
        </div>

        {/* Payment Button */}
        <div>
          <button
            onClick={handlePayment}
            className="w-full py-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Pay Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
