import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import ReactToPrint from "react-to-print";
import { IoPrint } from "react-icons/io5"; // Print Icon
import {
  INVOICE_CRUD_API,
  SETTINGS_CRUD_API,
} from "../../hooks/APIHooks";

// Component to render the invoices in a printable format
const AllInvoicesPrintable = React.forwardRef((props, ref) => {
  const { invoices, companySettings } = props;
  const { invoice } = props;

  const products = invoice?.products ? JSON.parse(invoice.products) : [];

  const calculateProductTax = (price, quantity) => {
    return (price * quantity * gstRate) / (100 + gstRate);
  };

  // Calculate subtotal, discount, and total
  const subtotal = products.reduce(
    (acc, product) =>
      acc +
      (parseFloat(product.price) || 0) * (parseFloat(product.quantity) || 0),
    0
  );
  const discount = parseFloat(invoice?.discount) || 0;
  const total = subtotal - discount;

  const gstRate = parseFloat(companySettings.Tax) || 18; // Assuming the Tax setting holds the Excluded GST percentage
  const grandTotal = total;

  // Format date utility
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);
  };

  return (
    <div
    ref={ref}
    className="p-4 bg-white"
    style={{
      width: '210mm',
      minHeight: '297mm',
      margin: '0 auto',
      pageBreakAfter: 'always',
    }}
  >
      <div className="space-y-8">
        {invoices.map((invoice, index) => {
          const products = JSON.parse(invoice.products || "[]");
          const subtotal = products.reduce(
            (acc, product) =>
              acc +
              (parseFloat(product.price) || 0) *
                (parseFloat(product.quantity) || 0),
            0
          );
          const discount = parseFloat(invoice.discount) || 0;
          const total = invoice.total_amount - discount;

          return (
            <div
          key={invoice.id}
          className="p-4 border rounded-lg shadow-sm mb-6"
          style={{ pageBreakBefore: index === 0 ? "auto" : "always" }} // Add page break before each new invoice
        >
              {/* Header Section */}
              <h1 className="text-[10px] text-center font-bold text-gray-800">
                {companySettings.companyName}
              </h1>
              <h1 className="text-[10px] text-center mx-auto text-gray-800 w-1/2">
                {companySettings.companyAddress}
              </h1>
              <h1 className="text-[10px] text-center mx-auto text-gray-800 w-1/2">
                {companySettings.GSTnumber}
              </h1>

              <div className="flex justify-between items-center mb-6">
                <div>
                  <img
                    src={companySettings.companylogo}
                    alt="Company Logo"
                    className="w-16 h-auto mx-auto object-cover"
                  />
                  <h1 className="text-[14px] sm:text-3xl font-bold text-gray-800">
                    Invoice
                  </h1>
                </div>
                <div className="text-gray-600 text-[12px] sm:text-sm">
                  <div>
                    Invoice Number:{" "}
                    <strong>{invoice?.invoice_number || "N/A"}</strong>
                  </div>
                  <div>
                    Invoice Date:{" "}
                    <strong>{formatDate(invoice?.invoice_date)}</strong>
                  </div>
                </div>
              </div>

              <div className="border-t border-gray-300 mb-6"></div>

              {/* Customer and Invoice Details Section */}
              <div className="grid grid-cols-2 md:grid-cols-2 gap-6 mb-6">
                <div className="bg-gray-100 p-4 rounded-md shadow-sm">
                  <h2 className="text-[14px] font-semibold mb-2">
                    Customer Details
                  </h2>
                  <div className="text-[14px]">
                    <strong>Name:</strong> {invoice?.customers_name || "N/A"}
                  </div>
                  <div className="text-[14px]">
                    <strong>Email:</strong> {invoice?.customers_email || "N/A"}
                  </div>
                  <div className="text-[14px]">
                    <strong>Phone:</strong> {invoice?.customers_phone || "N/A"}
                  </div>
                  <div className="text-[14px]">
                    <strong>Address:</strong>{" "}
                    {invoice?.customers_address || "N/A"}
                  </div>
                </div>
                <div className="bg-gray-100 p-4 rounded-md shadow-sm">
                  <h2 className="text-[14px] font-semibold mb-2">
                    Invoice Details
                  </h2>
                  <div className="text-[14px]">
                    <strong>Due Date:</strong>{" "}
                    {formatDate(invoice?.due_date) || "N/A"}
                  </div>
                  <div className="text-[14px]">
                    <strong>Discount:</strong> {invoice?.discount || "N/A"}
                  </div>
                  <div className="text-[14px]">
                    <strong>Total Amount:</strong>₹ {invoice.total_amount}
                  </div>
                  <div className="text-[14px]">
                    <strong>
                      Status:
                      <a
                        className={`text-[14px] ${
                          invoice?.status === "Pending" ? "text-red-500" : ""
                        }`}
                      >
                        {" "}
                        {invoice?.status || "N/A"}
                      </a>
                    </strong>
                  </div>
                </div>
              </div>

              <div className="border-t border-gray-300 mb-6"></div>

              {/* Billing Section */}
              <div className="bg-gray-100 p-4 rounded-md shadow-sm overflow-x-auto">
                <h2 className="text-[14px] font-semibold mb-2">Billing</h2>
                <table className="min-w-full bg-white border border-gray-300">
                  <thead>
                    <tr>
                      <th className="py-1 px-2 border-b text-[12px] sm:text-[14px] text-left">
                        Products
                      </th>
                      <th className="py-1 px-2 border-b text-[12px] sm:text-[14px] text-center">
                        Quantity
                      </th>
                      <th className="py-1 px-2 border-b text-[12px] sm:text-[14px] text-center">
                        Price
                      </th>
                      <th className="py-1 px-2 border-b text-[12px] sm:text-[14px] text-center">
                        Tax ({parseFloat(companySettings.Tax)}%)
                      </th>
                      <th className="py-1 px-2 border-b text-[12px] sm:text-[14px] text-right">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.length > 0 ? (
                      products.map((product, index) => {
                        const productTax = calculateProductTax(
                          parseFloat(product.price) || 0,
                          parseFloat(product.quantity) || 0
                        );
                        return (
                          <tr key={index}>
                            <td className="py-2 text-[14px] px-4 border-b">
                              <div className="tracking-tight leading-tight">
                                <span className="text-[10px] font-bold">
                                  {product.category} -
                                </span>
                                <span className="text-[10px] font-bold">
                                  {product.name}
                                </span>{" "}
                                <br />
                                <span className="text-[10px]">
                                  {product.description}
                                </span>
                              </div>
                            </td>
                            <td className="py-2 text-[14px] px-4 text-center border-b">
                              {product.quantity || "N/A"}
                            </td>
                            <td className="py-2 text-[14px] px-4 text-center border-b ">
                              ₹{parseFloat(product.price).toFixed(2) || "N/A"}
                            </td>
                            <td className="py-2 text-[14px] px-4 text-center border-b">
                              ₹{productTax.toFixed(2)}
                            </td>
                            <td className="py-2 text-[14px] px-4 text-right border-b">
                              ₹{(product.price * product.quantity).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="5" className="py-2 px-4 text-center">
                          No products available
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="4" className="text-right px-2 text-[14px]">
                        Subtotal:
                      </td>
                      <td className="text-right px-2 text-[14px]">
                        ₹ {subtotal.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="4"
                        className="text-right px-2 text-[14px] font-bold"
                      >
                        Discount:
                      </td>
                      <td className="text-right px-2 text-[14px]">
                        ₹ {discount.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="4"
                        className="text-right px-2 text-[14px] font-bold"
                      >
                        Total (Excluding GST):
                      </td>
                      <td className="text-right px-2 text-[14px]">
                        ₹ {total.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4" className="text-right px-2 text-[14px]">
                        GST ({gstRate}%):
                      </td>
                      <td className="text-right px-2 text-[14px]">
                      ₹ {invoice.GSTAmount}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="4"
                        className="text-right px-2 text-[16px] font-bold"
                      >
                        Grand Total:
                      </td>
                      <td className="text-right px-2 text-[16px]">
                        ₹ {invoice.total_amount}
                      </td>
                    </tr>
                  </tfoot>
                </table>

                {/* Box for Additional Information */}
                <div className="flex pt-2">
                  <div className="w-1/2 bg-gray-100 border border-gray-300 rounded-md p-2">
                    <p className="text-black text-[8px]">
                      Dear Valued Client,
                      <br />
                      <li>
                        We strive to ensure a smooth and secure transaction
                        process for all our customers. Please follow these
                        safety guidelines upon receiving your invoice.
                      </li>
                      <li>
                        Inspect Your Invoice: Carefully review the invoice upon
                        receipt. Ensure that all items, services, and prices are
                        accurate. If you notice any discrepancies, report them
                        immediately.
                      </li>
                      <li>
                        Report Any Issues: If you detect any errors or
                        suspicious activity related to the invoice or payment,
                        contact our support team right away. We are dedicated to
                        resolving any concerns and safeguarding your financial
                        transactions.
                      </li>
                    </p>
                  </div>
                  <div className="w-1/2 bg-gray-100 border border-gray-300 rounded-md p-2 text-right">
                  
                  </div>
                </div>
                <p className="text-black text-[8px] pt-2">
                  All disputes are subject to Visakhapatnam locality only.
                </p>
                <p className="text-black text-[8px]">
                  <strong>Note:</strong> This is a computer generated invoice &
                  does not required signature
                </p>
                <p className="bg-gray-500 text-white px-2 text-[10px] mt-2 text-center">
                  Invoice from {companySettings.companyName}
                </p>

                <div className="text-center mt-2">
                  <p className="text-[10px] font-bold text-gray-800">
                    Thank you! Please do visit us again! :)
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

// Main component to download all invoices
const DownloadAllInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [companySettings, setCompanySettings] = useState({});
  const printableRef = useRef();

  useEffect(() => {
    // Fetch all invoices
    axios
      .get(INVOICE_CRUD_API)
      .then((response) => setInvoices(response.data))
      .catch((error) => console.error("Error fetching invoices:", error));

    // Fetch company settings
    axios
      .get(SETTINGS_CRUD_API)
      .then((response) => setCompanySettings(response.data))
      .catch((error) => console.error("Error fetching settings:", error));
  }, []);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Download All Invoices</h1>
      <ReactToPrint
        trigger={() => (
          <button className="bg-blue-600 text-white px-4 py-2 rounded shadow hover:bg-blue-500 flex items-center">
            <IoPrint className="mr-2" />
            Download PDF
          </button>
        )}
        content={() => printableRef.current}
      />
      <div className="hidden">
        <AllInvoicesPrintable
          ref={printableRef}
          invoices={invoices}
          companySettings={companySettings}
        />
      </div>
    </div>
  );
};

export default DownloadAllInvoices;
