import React, { useState, useEffect } from "react";
import axios from "axios";
import { CUSTOMERS_CRUD_API } from "../hooks/APIHooks";
import { IoPencilSharp, IoTrashSharp } from "react-icons/io5";

const ClientsPage = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [sex, setSex] = useState("Male");
  const [editId, setEditId] = useState(null);
  const [message, setMessage] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (phoneFilter) {
      setFilteredCustomers(
        customers.filter((customer) => customer.phone.includes(phoneFilter))
      );
    } else {
      setFilteredCustomers(customers);
    }
  }, [phoneFilter, customers]);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(CUSTOMERS_CRUD_API);
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const handleAddOrUpdate = async (event) => {
    event.preventDefault();
    try {
      const data = { name, email, phone, address, sex };
      if (editId) {
        await axios.put(`${CUSTOMERS_CRUD_API}?id=${editId}`, data);
        setMessage("Customer updated successfully");
      } else {
        await axios.post(CUSTOMERS_CRUD_API, data);
        setMessage("Customer added successfully");
      }
      fetchCustomers();
      resetForm();
    } catch (error) {
      console.error("Error saving customer:", error);
      setMessage("Error saving customer");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      try {
        await axios.delete(`${CUSTOMERS_CRUD_API}?id=${id}`);
        setMessage("Customer deleted successfully");
        fetchCustomers();
      } catch (error) {
        console.error("Error deleting customer:", error);
        setMessage("Error deleting customer");
      }
    }
  };

  const handleEdit = (customer) => {
    setName(customer.name);
    setEmail(customer.email);
    setPhone(customer.phone);
    setAddress(customer.address);
    setSex(customer.sex);
    setEditId(customer.id);
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setSex("Male");
    setEditId(null);
    setMessage("");
  };

  return (
    <div className="p-4 mx-auto mb-10">
      <h1 className="text-3xl font-bold text-center">Our Clients</h1>
      {message && (
        <div className="mb-6 p-4 text-white bg-violet-500 rounded-md">
          {message}
        </div>
      )}

      <form
        onSubmit={handleAddOrUpdate}
        className="mb-8 bg-white p-6 rounded-md shadow-md"
      >
        <div className="grid grid-cols-2 md:grid-cols-2 gap-6">
          {/* Form Fields */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Phone</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Address</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Sex</label>
            <select
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              required
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
        </div>
        <button className="mt-4 w-full py-2 bg-violet-600 text-white rounded-md">
          {editId ? "Update Customer" : "Add Customer"}
        </button>
      </form>

      <input
        type="text"
        placeholder="Filter by phone"
        value={phoneFilter}
        onChange={(e) => setPhoneFilter(e.target.value)}
        className="mb-4 p-2 border border-gray-300 rounded-md"
      />

      {/* Card Layout */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {filteredCustomers.map((customer) => (
          <div
            key={customer.id}
            className="p-4 border rounded-lg shadow-md bg-white"
          >
            <h3 className="text-lg font-semibold">{customer.name}</h3>
            <p className="text-sm text-gray-600">Email: {customer.email}</p>
            <p className="text-sm text-gray-600">Phone: {customer.phone}</p>
            <p className="text-sm text-gray-600">Address: {customer.address}</p>
            <p className="text-sm text-gray-600">Sex: {customer.sex}</p>
            <div className="mt-4 space-x-2 flex justify-between">
              <button
                onClick={() => handleEdit(customer)}
                className="bg-violet-600 text-white px-4 py-2 rounded-md"
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(customer.id)}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientsPage;
